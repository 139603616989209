import React, { Component } from 'react'
import faqStyles from './faq.module.scss'

const Faqs = () => {
    return (
        <div className={"container " + faqStyles.faqs__list}>

            <h2>Frequently Asked Questions</h2>

            <FaqItem question="What is High Availability?" answer="<p>High Availability is designed to keep your website online, maximise reliability and eliminate the negative impacts that downtime can implement on a business.</p>

            <p>Host3’s high availability plans will continuously monitor the health of your website and take any necessary action to keep that website online, if for any reason the primary server fails, backup servers are instantly ready to take their place without any user input or changes.</p>" />

            <FaqItem question="What is a Managed Hosting plan?" answer="<p>Managed is a helping hand for your website that you can use as often or little as you’d like, if you are on the run and need an email setup, let us know. If you’re experiencing an issue with your website's code, we can look at that, we can look at anything server, code or design related and offer advice and support.</p>" />

            <FaqItem question="Will you transfer my website?" answer="<p>Host3 offers a free migration service for all packages on our website, there are no prerequisites, if your website will run on our servers we can move it whether it be manually or through a server transfer.</p>" />

            <FaqItem question="What If I go over my resources?" answer="<p>All our packages offer a generous amount of resources, enough to meet even the most demanding businesses. If you wish to have more space or bandwidth you easily upgrade and downgrade the site in your control panel, we will always notify you if you are anywhere close to the limits of your package. If you use all of your limits and do not wish to upgrade, you will not receive any additional charges.</p>

            <p>Server resources like CPU and ram are uniquely yours, another busy website cannot affect the performance of your site. If for any reason your site requires something more, we can work with you to find a solution.</p>

            <p>As always reach out to us via ticket or live chat!</p>" />

            <FaqItem question="Do you have any guarantees or agreements?" answer="<p>Host3 offers a 30-day money-back guarantee for all the packages listed on our website, if for you any reason you wish to cancel, you will receive a full refund as long as your account has not broken any of the terms of service.</p>

            <p>Please note refunds do not apply to bespoke solutions as they have had significant admin time to create.</p>

            <p>Our standard hosting packages come with a 99.95% uptime SLA and High Availability hosting comes with a 100% uptime SLA.</p>

            <p>Outside of scheduled server and network maintenance. if we are unable to meet the SLA offered, we offer 10% of credit towards your affected plans invoice for every hour downtime, up to 50% of your total bill.</p>" />

            <FaqItem question="Do you have a minimum contract length?" answer="<p>All hosting solutions are billed on a monthly rolling basis and can be canceled, upgraded, or downgraded at any time, there are no additional fees and no notice is required.</p>" />
        </div>
    )
}

class FaqItem extends Component {
    constructor (props) {
        super(props)
        this.toggleContent = this.toggleContent.bind(this)
        this.state = {
            showContent: false
        }
    }

    toggleContent (e) {
        e.preventDefault()
        this.setState({
            showContent: !this.state.showContent
        })
    }

    render() {
        const {showContent} = this.state
        const toggleClass = showContent === true ? faqStyles.faqs__item__active : ""

    return (
        <div className={`${faqStyles.faqs__item} ${toggleClass}`} onClick={this.toggleContent}>

            <div className={faqStyles.faqs__item__question}>
                <h4><i></i>{this.props.question}</h4>
            </div>

            <div className={faqStyles.faqs__item__answer}>
                <div dangerouslySetInnerHTML={{ __html: this.props.answer }} />
            </div>

        </div>
    )
    }
}

export default Faqs