import React from 'react'
import { Link } from 'gatsby'
import supportStyles from './support.module.scss'

const Support = (props) => {

    return (
        <section className={supportStyles.support}>
            <div className={"container " + supportStyles.support__container}>
                <a href="https://account.host3.co.uk/supporttickets.php" className={"moveup " + supportStyles.support__item}>
                    <div className={supportStyles.support__item__icon}>
                        <i className="icon icon-ticket"></i>
                    </div>
                    <div className={supportStyles.support__item__content}>
                        <h3>Support Ticket</h3>

                        <p>Open a ticket for technical or billing support or alternatively try our live chat for a quicker response</p>
                    </div>
                </a>

                <a href="https://account.host3.co.uk/clientarea.php" className={"moveup " + supportStyles.support__item}>
                    <div className={supportStyles.support__item__icon}>
                        <i className="icon icon-chat"></i>
                    </div>
                    <div className={supportStyles.support__item__content}>
                        <h3>Live Chat</h3>

                        <p>Dedicated chat support for any client who needs their answers quickly</p>
                    </div>    
                </a>

                <a href="https://account.host3.co.uk/clientarea.php" className={"moveup " + supportStyles.support__item}>
                    <div className={supportStyles.support__item__icon}>
                        <i className="icon icon-phone"></i>
                    </div>
                    <div className={supportStyles.support__item__content}>
                        <h3>Phone</h3>

                        <p>Contact your dedicated consultant for any queries regarding your account</p>
                    </div>  
                </a>

                <Link to="/contact/" className={"moveup " + supportStyles.support__item}>
                    <div className={supportStyles.support__item__icon}>
                        <i className="icon icon-question-circle-o"></i>
                    </div>
                    <div className={supportStyles.support__item__content}>
                        <h3>Pre-Sales</h3>

                        <p>Here to help create the perfect solution for your website</p>
                    </div>
                </Link>

            </div>

            {props.children}
        </section>
    )
}

export default Support