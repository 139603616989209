import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import SubHeader from '../components/sub-header'
import Support from '../components/support'
import Logos from '../components/logos'
import Faqs from '../components/faq'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const SupportOvr = () => {
    return (
        <Store>
            <Head title="Support" />
            <Navigation />
            <Header disable />
            <SubHeader title="Hi." span="How can we help?" />
            <Support/>
            <Faqs />
            <Logos />
            <Talk />
            <Footer />
            <Cookies />
        </Store>
    )
}

export default SupportOvr
